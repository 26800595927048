<template>
	<div class="p-homeindex">
		<login ref='child'></login>
		<div class="p-home-head">
			<div class="p-home-head-clude">
				<div :class="['p-home-allspan', whichid==item.id ? 'p-home-allspan p-home-allspan-c':'']" v-for="(item,index) in chooselist"
				 :key="index" @mouseenter="whichid=item.id" @click="classtype(item.id,index)">
					{{item.cat_name}}
				</div>
			</div>
		</div>
		<div class="p-home-swpier-bg">
			<el-carousel class="p-home-swpier">
				<el-carousel-item class="p-home-swpier-i" v-for="(item,index) in data.bannerList" :key="index">
					<img class="p-s-img" :src="item.image" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="p-home-second-choose">
			<div class="p-home-ul">
				<ul>
					<li :class="['p-home-second-choose-li', tlike==0 ? 'p-home-second-choose-li p-home-second-choose-li-c':'']"
					 @mouseenter="tlike=0" @click="interest(0)">
						微专业
					</li>
					<li :class="['p-home-second-choose-li', tlike==item.id ? 'p-home-second-choose-li p-home-second-choose-li-c':'']"
					 v-for="(item,index) in data.interestCatList" :key='index' @mouseenter="tlike=item.id" @click="interest(item.id,item.cat_name)">
						{{item.cat_name}}
					</li>
				</ul>
				<div class="p-thelast" @click="chooseilike">更改兴趣</div>
			</div>
		</div>
		<!-- 微专业这一π -->
		<div v-if="whenmiro==1" class="p-miros">
			<div>
				<div class="p-microsoft">
					<div :class="['p-microsoft-item ', choose==index ? 'p-microsoft-item p-microsoft-item-c ':'']" v-for="(item,index) in microsoftindexclass.cat"
					 :key="index" @click="whichchoose(index,item.id)" @mouseenter="choose = index">
						<img :src="item.icon" />
						<div>{{item.cat_name}}</div>
					</div>
				</div>
				<div>
					<div class="p-home-room">
						<div class="p-home-show-big">推荐微专业</div>
						<div class="p-home-room-about">
							<div :class="['c-s-a-item micr-123', (index+1)%5==0 ? 'c-s-a-item hl-ml23':'']" v-for="(item,index) in microsoftindex.special_goods"
							 @click="tomicrosft(item.micro_gid)">
								<div class="c-s-a-itemi-img"><img class="c-s-a-itemi-img" :src="item.goods_img" /></div>
								<div class="c-s-item-title">{{item.goods_name}}</div>
								<div class="c-s-item-content">{{item.goods_info}}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="p-home-room">
						<div class="p-home-show-big">限时优惠</div>
						<div class="p-home-room-about">
							<img :class="['p-microsoft-item-img hui-123', (index+1)%4==0 ? 'p-microsoft-item-img hl-ml23':'']" v-for="(item,index) in microsoftindex.discount_goods"
							 @click="tomicrosft(item.micro_gid)" :src="item.goods_img" />
						</div>
					</div>
				</div>
			</div>
			<div class="p-top-body">
				<div class="p-top-left">
					<div :class="['p-top-left-item', choose==index ? 'p-top-left-item p-top-left-item-c':'']" v-for="(item,index) in microsoftindexclass.cat"
					 :key="index" @click="whichchoose(index,item.id)" :id="index" @mouseenter="whichchoose(index,item.id)">
						<div>{{item.cat_name}}</div> <img class="p-mrLeft" v-if="choose==index" src="../../../assets/img/home/mrleft.png" />
					</div>
				</div>
				<div class="p-top-right p-top-mr50">
					<div class="p-top-head">
						<img src="../../../assets/img/home/shuaxuan.png" />
						<div>分类筛选</div>
						<div :class="['p-top-item', chooseaa==indexa ? 'p-top-item p-top-item-c':'']" v-for="(item,indexa) in  microsoftindexclass.cat[choose].child"
						 :key="indexa" @click="wchooseaa(indexa,item.id)">
							<div>{{item.cat_name}}</div>
						</div>
					</div>
					<div class="p-top-right-body">
						<div class="p-right-body-item" v-for="(item,index) in microsoftindexclass.goodslist" :key="index" @click="tomicrosft(item.micro_gid)">
							<img :src="item.goods_img" />
							<div>{{item.goods_name}}</div>
							<div style="color: #999999;font-size: 12px;">{{item.goods_info}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 直播间 -->
		<div v-if="whenmiro==0">
			<div class="p-home-room">
				<div class="p-home-show-big">优选直播间</div>
				<div class="p-home-room-about " style="display: flex;justify-content: space-between !important;">
					<el-card class="p-home-room-item" v-for="item in data.liveList.data.livingGoodsList">
						<div @click='toroom(index)'>
							<img :src="item.goods_img" class="p-home-room-image" />
							<div class="p-home-room-right">
								<div class="p-home-room-right-item ">
									{{item.goods_name}}
								</div>
								<div class="p-home-room-right-item" style="color: #666666 ;">
									讲师：{{item.teacher_name}}
								</div>
								<div class="p-home-room-right-item">
									<div>{{item.live_time}}</div>
									<div class="p-home-room-button" @click="showget">
										预约
									</div>
								</div>
							</div>
						</div>
					</el-card>
				</div>
			</div>
			<!-- 推荐课程 -->
			<div>
				<div class="p-home-room">
					<div class="p-home-show-big">VIP推荐课程</div>
				</div>
				<div class="p-home-room">
					<div class="p-home-left-right">
						<img class="p-home-room-about-img-left" @click="toAheadItem" src="../../../assets/img/home/right.png" />
						<img @click="toNextItem" class="p-home-room-about-img-right" src="../../../assets/img/home/left.png" />
					</div>
					<div class="p-home-room-about-b" id="scollItems">
						<div class="p-hidden-class">
							<div class="p-home-vip" style="float: left;display: block;" v-for="(item,index) in data.vipGoodsList" :key="index"
							 @click="todetail(item.id)">
								<div class="p-home-vip-image">
									<img :src="item.goods_img" class="p-home-vip-image" />
								</div>
								<div>{{item.goods_name}}</div>
								<div>{{item.goods_trait}}</div>
								<div><span>{{item.order_count}}人在学</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="p-home-room" v-for="(item,index) in data.cateGoodsList" :key="index">
					<div class="p-home-show-big">{{item.cat_name}}</div>
					<div class="p-home-room-about">
						<div :class="['c-s-a-item ml23', (indexa+1)%6==0 ? 'c-s-a-item hl-ml23':'']" v-for="(list,indexa) in item.goods_list"
						 :key="indexa" @click="todetail(list.goods_id)">
							<div class="c-s-a-itemi-img"><img class="c-s-a-itemi-img" :src="list.goods_img" /></div>
							<div class="c-s-item-title">{{list.goods_name}}</div>
							<div class="c-s-item-content">{{list.school_name}} {{list.order_count}}人学过</div>
							<div style="display: flex;flex-wrap: wrap;align-items: flex-start;">
								<div class="c-span1">￥{{list.goods_price}}</div>
								<div class="c-span2"><span>vip免费</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-home-look-all" @click="tbchoose()">
				<span>查看{{name}}全部标签课程</span><i class="el-icon-arrow-right"></i>
			</div>
		</div>
		<totop></totop>
	</div>
</template>

<script>
	import login from '../../common/login.vue';
	import totop from '../../common/totop.vue';
	export default {
		metaInfo: {
			title: '逸教云课堂', // set a title
			meta: [{ // set meta
					name: '智能教育',
					content: '新一代智能教育，让你的孩子与众不同'
				},
				{
					name: '赢在起跑线上',
					content: '想学习到逸教云课堂'
				}
			],
			link: [{ // set link
				rel: 'asstes',
				href: 'https://ykt.qdy2020.com/'
			}]
		},
		data() {
			return {
				whichid: 1,
				tlike: 1,
				chooselist: [{
					name: "职场提升",
					id: 1,
					index: "/home"
				}],
				data: [],
				choose: 1,
				tagsList: [
					"https://zhaosheng.qdy2020.com/images/dBpuChUiqlvjVoVsaQfiZke3L4NmjU8i9gSbVV0X.jpeg",
					"https://zhaosheng.qdy2020.com/images/NRqN7g5lAfjgF57OOOx179b3zYbBDZTD4uaEi8GI.jpeg"
				],
				collapse: false,
				micros: false,
				whenmiro: 0,
				achoose: "",
				getcatelistbyid: [],
				name: "",
				microsoftindex: {},
				microsoftindexclass: {},
				chooseaa: 0,
				size: 5
			};
		},
		components: {
			login,
			totop
		},
		created() {
			this.getdata()
			this.getgoodscate()
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll, true)
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true)
		},
		methods: {
			//微专业
			tomicrosft(e) {
				this.$router.push({
					path: '/microcoursedetail',
					query: {
						id: e
					}
				})
			},
			handleScroll() {
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				if (this.whenmiro == 1) {
					if (top > 1800) {
						this.$router.push({
							path: '/classTop'
						})
					}
				}
			},
			wchooseaa(e, id) {
				this.chooseaa = e
				this.getmicrosoftclass(id)
			},
			getmicrosoftclass(id) {
				this.$get('index/microcatgoods/', {
					cat_id: id,
					size: this.size

				}).then(response => {
					if (response.error == 0) {
						this.microsoftindexclass = response
					} else {
						return
					}
				});
			},
			getmicrosoft() {
				this.$get('index/smartindex/', {}).then(response => {
					if (response.error == 0) {
						this.microsoftindex = response.data
					} else {
						return
					}
				});
			},
			toAheadItem() {
				document.getElementById('scollItems').scrollLeft -= 1000;
			},
			toNextItem() {
				document.getElementById('scollItems').scrollLeft += 1000;
			},
			showget(e) {
				if(!localStorage.getItem('token')){
					this.$message.error("请登录")
					return
				}
				this.$refs.child.toshow(5,e);
			},
			whichchoose(e, id) {
				this.choose = e
				var oDiv = document.getElementById(e);
				var t = document.createElement("input");
				t.type = "text";
				oDiv.insertBefore(t, null);
				t.focus();
				oDiv.removeChild(t);
				this.getmicrosoftclass(id)
			},
			//优选直播间
			toroom(e) {
				if (!localStorage.getItem('token')) {
					/* this.$refs.child.toshow(1); */
					this.$message.error("请登录")
					return
				}
				return
				this.$router.push({
					path: '/courseDetailFree',
					query: {
						id: e
					}
				})
			},
			//分类选择哪里
			tbchoose(e, index) {
				this.bchoose = e
				if (!localStorage.getItem('token')) {
					//this.$refs.child.toshow(1);
					this.$message.error("请登录")
					return
				}
				this.$router.push({
					path: '/search',
					query: {
						id: e,
						choose: index,
						data: this.chooselist,
					}
				})
			},
			//课程详情
			todetail(e) {
				if (!localStorage.getItem('token')) {
					//this.$refs.child.toshow(1);
					this.$message.error("请登录")
					return
				}
				this.$router.push({
					path: '/courseDetail',
					query: {
						id: e
					}
				})
			},
			//感兴趣
			interest(e, name) {
				this.tlike = e
				if (!localStorage.getItem('token')) {
					//this.$refs.child.toshow(1);
					this.$message.error("请登录")
					return
				}
				if (e == 0) {
					this.whenmiro = 1
					this.getmicrosoft()
					this.getmicrosoftclass(0)
				} else {
					this.whenmiro = 0
					this.name = name
					this.getdata(e)
				}
			},
			chooseilike() {
				this.$refs.child.getgoodscate(this.chooselist)
				if (!localStorage.getItem('token')) {
					//this.$refs.child.toshow(1);
					this.$message.error("请登录")
					return
				}
				this.$refs.child.toshow(6);
			},
			//课程分类
			classtype(e, index) {
				this.whichid = e
				if (!localStorage.getItem('token')) {
					// this.$refs.child.toshow(1);
					this.$message.error("请登录")
					return
				}
				this.tbchoose(e, index)
			},
			//获取分类
			getgoodscate() {
				var that = this
				this.$get('goodscate/getgoodscatetreelist', {}).then(response => {
					if (response.error == 0) {
						this.chooselist = response.data.goodsCateTreeList
					} else {
						return
					}
				});
			},
			//分类下面的内容
			getgoodcontent(e) {
				var that = this
				this.$get('goodscate/getcatelistbyid', {
					cate_id: e
				}).then(response => {
					if (response.error == 0) {
						this.getcatelistbyid = response.data.cateList
					} else {
						return
					}
				});
			},
			//通用的获取本页面数据后续出现
			getdata(e) {
				this.$get("index/index", {
					cat_id: e
				}).then(response => {
					if (response.error == 0) {
						this.data = response.data
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			setname(nickname, avatarurl) {
				this.$router.push("/home")
			},
		},
	};
</script>

<style>
	.p-homeindex {
		height: auto;
		width: 100%;
	}

	.p-home-head {
		width: 100%;
		height: 35px;
		background: #FFFFFF;
	}

	.p-home-head-clude {
		width: 80%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.p-home-allspan {
		height: 30px;
		line-height: 30px;
		text-align: center;
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		margin-right: 70px;
	}

	.p-home-allspan-c {
		color: #00D0B0 !important;
		border-bottom: 2px solid #00D0B0 !important;
	}

	.p-home-swpier-bg {
		width: 100%;
		height: 500px;
		background: #C7CF55;
	}

	.p-s-img {
		width: 100% !important;
		height: 100% !important;
	}

	.p-home-swpier {
		width: 100%;
		height: 500px !important;
		margin: 0 auto;
	}

	.p-home-swpier-i {
		width: 100%;
		height: 500px !important;
		margin: 0 auto;
	}

	.p-home-second-choose {
		width: 100%;
		height: 60px;
		background: #FFFFFF;
		line-height: 60px;
	}

	.p-home-second-choose ul {
		width: 80%;
		margin: 0 auto;
		list-style: none;
		display: flex;
		justify-content: center;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.p-home-ul {
		width: 80%;
		margin: 0 auto;
	}

	.p-home-second-choose-li {
		margin-right: 49px;
	}

	.p-home-second-choose-li-c {
		color: #00D0B0;
		border-bottom: 2px solid #00D0B0;
		cursor: pointer;
	}

	.p-thelast {
		width: 83px;
		height: 28px;
		background: #F3F3F3;
		line-height: 28px;
		text-align: center;
		border-radius: 14px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		position: relative;
		margin-top: -45px;
		cursor: pointer;
		margin-left: 95%;
	}

	/* 直播间 */
	.p-home-room {
		width: 80%;
		margin: 0 auto;
		margin-top: 50px;
		margin-bottom: 40px;
		cursor: pointer;
	}

	.p-home-show-big {
		font-size: 30px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		margin-bottom: 25px;
		color: #333333;

	}

	.p-home-room-about {
		width: 100%;
		height: auto;
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
	}

	.p-home-room-about div:hover {
		color: #00D0B0;
	}

	.p-home-room-about-b {
		height: 305px;
		width: 100%;
		overflow: hidden;
	}

	.p-hidden-class {
		width: 1800px;
		height: 300px;
	}

	.p-home-room-about-img-left {
		width: 21px;
		height: 40px;
		margin-left: -50px;
	}

	.p-home-room-about-img-right {
		width: 21px;
		height: 40px;
		margin-right: -50px;
		z-index: 200;
	}

	.p-home-room-item {
		width: 733px;
		height: 250px;
		background: #FFFFFF;
		border-radius: 15px;
		margin-top: 20px;
	}

	.p-home-room-image {
		width: 300px;
		height: 210px;
		border-radius: 15px;
		overflow: hidden;
		float: left;
	}

	.p-home-room-button {
		width: 95px !important;
		height: 41px;
		background: #00D0B0;
		border-radius: 21px;
		text-align: center;
		margin-top: -90px !important;
		color: #FFFFFF !important;
		font-size: 14px !important;
		line-height: 41px;
	}

	.p-home-room-right {
		float: left;
		width: 270px;
		margin-left: 20px;
		display: flex;
		flex-direction: column;

	}

	.p-home-room-right :nth-child(1) {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.p-home-room-right :nth-child(2) {
		margin-top: 38px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.p-home-room-right :nth-child(3) {
		margin-top: 180px !important;
	}

	.p-home-room-right-item {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		position: absolute;
		width: 368px;
		line-height: 36px;
	}

	.p-home-room-right :nth-child(3) {
		display: flex !important;
		flex-wrap: wrap !important;
		position: absolute;
		margin-top: 168px;
		width: 368px;
		justify-content: space-between !important;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		line-height: 36px;
	}

	.p-home-room-right :nth-child(3) :nth-child(1) {
		float: left;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		line-height: 36px;
	}

	/* vip */
	.p-home-vip {
		width: 270px !important;
		height: 300px !important;
		background: #FFFFFF;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
		border-radius: 15px;
		margin-right: 21px;
		margin-top: 2px;
		margin-bottom: 2px;
		box-flex: unset;
	}

	.p-home-vip div:hover {
		color: #00D0B0;
	}

	.p-home-vip-image {
		width: 270px;
		height: 167px;
		background: #9A9A9A;
		border-radius: 15px 15px 0px 0px;
		overflow: hidden;
	}

	.p-home-vip :nth-child(2) {
		margin-left: 10px;
		margin-right: 10px;
		font-size: 16px;
		height: 30px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 30px;

	}

	.p-home-vip :nth-child(3) {
		margin-left: 10px;
		margin-right: 10px;
		font-size: 14px;
		max-height: 50px;
		display: -webkit-box;
		display: -ms-flexbox;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 25px;
	}

	.p-home-vip :nth-child(4) {
		margin-left: 10px;
		margin-right: 10px;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		float: right;
	}

	/* 微专业 */
	.p-miros {
		height: auto;
		width: 80%;
		margin: 0 auto;
	}

	.p-microsoft {
		height: auto;
		width: 80%;
		margin-bottom: 90px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 50px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-microsoft-item {
		height: 182px;
		width: 182px;
		cursor: pointer;
		margin-right: 31px;
		margin-left: 32px;
		background: #FFFFFF;
		box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
		border-radius: 15px;
		margin-top: 20px;
	}

	.p-microsoft-item-c {
		background: #E5FAF7;
		box-shadow: 0px 4px 11px 0px rgba(0, 151, 128, 0.2);
	}

	.p-microsoft-item img {
		width: 61px !important;
		height: 47px !important;
		margin-left: 60px !important;
		margin-top: 46px !important;
	}

	.p-microsoft-item div {
		width: 100% !important;
		text-align: center;
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-top: 25px;
	}

	.p-microsoft-item-img {
		width: 280px;
		height: 120px;
		border-radius: 5px;
		margin-right: 20px;
		margin-bottom: 20px;
	}

	.p-top-bodyb {
		width: 100%;
		margin-bottom: 30px;
	}

	.p-top-bodya {
		position: fixed;
		top: 0;
		height: 100%;
		width: 120%;
		left: -10%;
		background: #FFFFFF;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-bottom: 60px;
	}

	/* 	首页分类 */
	.p-home-gettype {
		width: 700px;
		height: 380px;
		background: #FFFFFF;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
	}

	/* 辅助类 */
	.p-mr23 {
		margin-right: 20px !important;
	}

	.ml23 {
		margin-right: 40.5px !important;
		margin-left: 0px !important;
	}

	.micr-123 {
		margin-right: 29.5px !important;
		margin-left: 0px !important;
	}

	.hui-123 {
		margin-right: 36px !important;
		margin-left: 0px !important;
	}

	.hl-ml23 {
		margin-right: 0px !important;
		margin-left: 0.5px !important;
	}

	/* 查看更多 */
	.p-home-look-all {
		width: 430px;
		height: 46px;
		border: 1px solid #E6E6E6;
		margin: 0 auto;
		text-align: center;
		line-height: 46px;
		font-size: 18px;
		font-weight: 400;
		cursor: pointer;
		color: #999999;
	}

	.p-home-look-all span:hover {
		color: #00D0B0 !important;
	}

	.p-home-left-right {
		height: 100px;
		width: 80%;
		position: absolute;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-top: 100px;
	}

	.p-top-mr50 {
		margin-left: 40px !important;
	}

	/* 鼠标辅助点击放大样式集合 */
	.el-card__body :hover img {
		transform: scale(1.4);
		transform: scale3d(1.05, 1.05, 1.05);
		-ms-transform: scale3d(1.05, 1.05, 1.05);
		-moz-transform: scale3d(1.05, 1.05, 1.05);
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		-o-transform: scale3d(1.05, 1.05, 1.05);
	}

	.el-card__body img {
		cursor: pointer;
		transition: all 0.6s;
	}

	.p-home-vip img {
		cursor: pointer;
		transition: all 0.6s;
	}

	.p-home-vip :hover img {
		transform: scale(1.4);
		transform: scale3d(1.2, 1.2, 1.2);
		-ms-transform: scale3d(1.2, 1.2, 1.2);
		-moz-transform: scale3d(1.2, 1.2, 1.2);
		-webkit-transform: scale3d(1.2, 1.2, 1.2);
		-o-transform: scale3d(1.2, 1.2, 1.2);
	}
</style>
